<template>
    <div>
        <NavBreadcrumb></NavBreadcrumb>
        <div class="panel-style device">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="归属机构" prop="officeId">
                    <el-select v-model="searchForm.orgId" placeholder="请选择用户机构" clearable @change="getStationOption">
                        <el-option v-for="item in orgOption" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="基站名称" prop="stationName">
                    <el-select v-model="searchForm.stationName" placeholder="请选择电站名称" clearable>
                        <el-option v-for="item in stationOption" :key="item.name" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地区名称" prop="name">
                    <el-cascader :props="props" clearable v-model="searchForm.areaId"></el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getTableData">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" border size="small" stripe style="width: 100%"
                      :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
                <el-table-column prop="deviceNum" label="设备编号" align="center"/>
                <el-table-column prop="stationName" label="基站名称" />
                <el-table-column prop="orgName" label="归属机构" />
                <el-table-column prop="areaName" width="200" label="归属地区" />
                <el-table-column prop="online" label="是否在线" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.online==='在线'"
                              style="cursor: pointer;background-color:#5FB878;width: 16px;height: 16px;line-height: 16px;padding: 0 7px;border-radius: 100px;" />
                        <span v-else-if="scope.row.online==='离线'"
                              style="cursor: pointer;background-color:#828080;width: 16px;height: 16px;line-height: 16px;padding: 0 7px;border-radius: 100px;" />
                    </template>
                </el-table-column>
                <el-table-column prop="ipcVersion" label="版本号" align="center" :formatter="versionFormatter">
                    <template slot-scope="scope">
                        <span v-if="scope.row.ipcVersion==='0'">
                           --
                        </span>
                        <span v-else>
                             {{scope.row.ipcVersion}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastReportTime" label="最近上报时间" align="center"/>
                <el-table-column prop="status" label="运维状态" align="center" :formatter="statusFormatter"/>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="openUpgradeDetailPage(scope.$index, scope.row)" type="danger">升级</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 15px;text-align: right;">
                <el-pagination background layout="total, prev, pager, next, jumper" :total="tableTotal"
                               @current-change="handleCurrentChange" :current-page.sync="curr"/>
            </div>

            <el-dialog id="emuUpgradeDialog" title="升级任务" :visible="upgradeDetailPageVisable" top="10vh"
                       @close="closeUpgradeDetailPage"  center :close-on-click-modal="false">

                <el-divider><i class="el-icon-news"></i></el-divider>

                <el-form :inline="false" :model="upgradeInfoForm" label-width="80px">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="电站名称">
                                <el-input v-model="upgradeInfo.stationName" style="width: 200px;" disabled="disabled"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="设备编号">
                                <el-input v-model="upgradeInfo.deviceNum" style="width: 200px" disabled="disabled"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="当前版本">
                                <el-input v-model="upgradeInfo.ipcVersion" style="width: 200px" disabled="disabled"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="设备类型">
                        <el-select placeholder="请选择设备类型" clearable @change="getStationOption" style="width: 200px" v-model="upgradeInfo.deviceType">
                            <el-option label="EMU固件" value="1"></el-option>
                            <el-option label="PCS1主" value="2"></el-option>
                            <el-option label="PCS1从" value="3"></el-option>
                            <el-option label="PCS2主" value="4"></el-option>
                            <el-option label="PCS2从" value="5"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="升级文件">
                        <div style="border:1px solid #DCDFE6;border-radius: 4px">
                            <el-row style="padding-left: 10px;">
                                <el-row>
                                    <el-col :span="6"><strong>文件名</strong></el-col>
                                    <el-col :span="6"><strong>文件路径</strong></el-col>
                                    <el-col :span="6"><strong>文件大小(Bytes)</strong></el-col>
                                    <el-col :span="6"><strong>Crc32值</strong></el-col>
                                </el-row>
                            </el-row>
                            <el-row  style="padding-left: 10px;">
                                <el-row>
                                    <el-col :span="6">{{upgradeInfo.fileName}}</el-col>
                                    <el-col :span="6">{{upgradeInfo.filePath}}</el-col>
                                    <el-col :span="6">{{upgradeInfo.fileSize}}</el-col>
                                    <el-col :span="6">{{upgradeInfo.crc32}}</el-col>
                                </el-row>
                            </el-row>
                            <el-row>
                                <el-button type="success" style="margin-left: 10px"
                                           icon="el-icon-thumb" round size="mini" @click="openFtpManagePage">选择</el-button>
                            </el-row>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="closeUpgradeDetailPage">取消</el-button>
                        <el-button type="danger" @click="startUpgrade">确认</el-button>
                    </el-form-item>

                    <el-divider v-if="upgradePorgressData.length > 0"><i class="el-icon-finished"></i></el-divider>
                    <el-form-item label="升级进度" v-if="upgradePorgressData.length > 0">
                        <div style="border:1px solid #DCDFE6;border-radius: 4px;height: 100%;padding: 5px">
                            <el-row v-for="item in upgradePorgressData">
                                <el-col :span="2">
                                    <span v-if="item.deviceType == 1" style="width: 70px">EMU</span>
                                    <span v-else-if="item.deviceType == 2" style="width: 70px">PCS1主</span>
                                    <span v-else-if="item.deviceType == 3" style="width: 70px">PCS1从</span>
                                    <span v-else-if="item.deviceType == 4" style="width: 70px">PCS2主</span>
                                    <span v-else-if="item.deviceType == 5" style="width: 70px">PCS2从</span>
                                </el-col>
                                <el-col :span="21" style="margin-top: 10px">
                                    <el-progress :text-inside="true" :color="customColorMethod" :stroke-width="20"
                                                 :percentage=item.progress></el-progress>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form-item>
                </el-form>
            </el-dialog>

            <el-dialog title="升级文件管理" :visible="ftpManagerPageVisable" top="10vh" ref="ftpManage"
                       @close="closeFtpManagePage"  center :close-on-click-modal="false">
                <FtpManage ref="ftpManagePage"></FtpManage>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {queryDeviceInfo, queryOrgInfo, queryCascaderAreaInfoByOrgId, queryStationInfoByParam, queryUpgradeProgressList} from "../../api/communication/upgradeApi";
    import {closeWebSocket, initWebSocket, webSocketSendMsg} from "../../api/webSocket";
    import NavBreadcrumb from "../../components/NavBreadcrumb";
    import FtpManage from "./ftpManage";
    export default {
        name: "UpgradeManage",
        provide(){
            return{
                setUpgradeInfo:this.setUpgradeInfo
            }
        },
        components: {FtpManage, NavBreadcrumb},
        data() {
            return {

                orgOption: [],
                stationOption:[],
                tableData:[],
                tableTotal: 0,
                curr: 1,
                size: 10,
                listLoading: false,
                searchForm: {

                    orgId: '',
                    stationName: '',
                    areaId:'',
                },
                upgradeInfoForm: {},
                currDeviceNum: '',
                props: {

                    lazy: true,
                    checkStrictly: true,
                    lazyLoad (node, resolve) {

                        let params = {

                            orgId: this.orgId,
                            level: node.level,
                            areaId: node.value
                        };
                        queryCascaderAreaInfoByOrgId(params).then((res) => {

                            resolve(res.data.list);
                        }).catch(err => console.error(err));
                    }
                },
                upgradeDetailPageVisable:false,
                ftpManagerPageVisable:false,
                upgradeInfo:{},
                upgradePorgressData:[],
                progressTimer: ''
            };
        },
        methods : {
            customColorMethod(percentage) {
                if (percentage < 30) {
                    return '#909399';
                } else if (percentage < 55) {
                    return '#e6a23c';
                } else {
                    return '#67c23a';
                }
            },
            queryUpgradeProgress() {

                this.upgradePorgressData = [];
                let params = {

                    deviceWholeId: this.upgradeInfo.deviceWholeId
                };
                queryUpgradeProgressList(params).then((res) => {

                    let result = res.data;
                    for (let i = 0; i < result.length; i++) {

                        let info = result[i];
                        this.upgradePorgressData.push(
                            {
                                deviceType: info.deviceType,
                                progress: Number(info.progress)
                            }
                        );
                    }
                }).catch(err=> console.log(err));
            },
            // 查询列表信息
            getTableData() {

                let areaIds = "";
                if (this.searchForm.areaId != null && this.searchForm.areaId != ''){

                    areaIds = this.searchForm.areaId.join(",");
                }
                let params = {

                    curr: this.curr,
                    size: this.size,
                    orgId: this.searchForm.orgId,
                    stationName: this.searchForm.stationName,
                    areaId: areaIds
                };
                this.listLoading = true;
                queryDeviceInfo(params).then((res) => {

                    this.tableTotal = res.data.totalNum;
                    this.tableData = res.data.listResult;
                    this.listLoading = false;
                }).catch(err=> console.log(err));
            },
            // 处理整机设备分页
            handleCurrentChange(val) {

                this.curr = val;
                this.getTableData();
            },
            openUpgradeDetailPage(index, row) {

                this.upgradeInfo.deviceWholeId = row.id;
                this.upgradeInfo.stationName = row.stationName
                this.upgradeInfo.deviceNum = row.deviceNum;
                if (row.ipcVersion === '0') {

                    this.upgradeInfo.ipcVersion = '--';
                } else {

                    this.upgradeInfo.ipcVersion = row.ipcVersion;
                }
                this.upgradeDetailPageVisable = true;

                // 定时查询升级进度
                this.queryUpgradeProgress();
                this.progressTimer = setInterval(this.queryUpgradeProgress, 10 * 1000 )
            },
            closeUpgradeDetailPage() {

                closeWebSocket();
                clearInterval(this.progressTimer);
                this.upgradeDetailPageVisable = false;
            },
            openFtpManagePage() {

                this.ftpManagerPageVisable = true;
                this.$nextTick(function() {

                    let params = {

                        action: 'GetFileList',
                        file: '/'
                    };
                    this.$refs.ftpManagePage.doFtpAction(params);
                });
            },
            closeFtpManagePage(){

                this.ftpManagerPageVisable = false;
            },
            setUpgradeInfo(item) {

                this.ftpManagerPageVisable = false;
                this.upgradeInfo.fileName = item.name;
                this.upgradeInfo.filePath = item.path;
                this.upgradeInfo.fileSize = item.size;
                this.upgradeInfo.crc32 = item.crc32;
            },
            // 获取机构列表
            getOrgList(){

                let params = {

                    curr: 0,
                    size: 0
                };
                queryOrgInfo(params).then((res) => {

                    this.orgOption = res.data.list;
                }).catch(err => console.error(err));
            },
            // 机构改变触发
            getStationOption() {

                let params = {

                    orgId : this.searchForm.orgId,
                };
                queryStationInfoByParam(params).then((res) => {

                    this.stationOption = res.data;
                }).catch(err => console.error(err));
                this.$forceUpdate();
            },
            // 版本格式化
            versionFormatter(row) {

                let v = row.ipcVersion.split(["."]);
                if (v.length > 2) {
                    return v[2] + "." + v[1] + "." + v[0];
                } else {
                    return row.ipcVersion;
                }
            },
            // 是否运维格式化
            statusFormatter (row) {

                if (row.status === "1") {

                    return "运维中";
                } else {

                    return "正常";
                }
            },
            startUpgrade() {

                let upgradeInfo = this.upgradeInfo;
                if (upgradeInfo.deviceType == null || upgradeInfo.deviceType == '') {

                    this.$message({
                        showClose: true,
                        message: '升级设备类型为必选项！',
                        type: 'warning',
                        offset: 20
                    });
                    return;
                }
                if (upgradeInfo.fileName == null ||  upgradeInfo.fileName == ''
                    || upgradeInfo.filePath == '' || upgradeInfo.filePath == null
                    || upgradeInfo.crc32 == '' || upgradeInfo.crc32 == null
                    || upgradeInfo.fileSize == null) {

                    this.$message({
                        showClose: true,
                        message: '必须选择一个升级文件！',
                        type: 'warning',
                        offset: 20
                    });
                    return;
                }
                initWebSocket("/emuUpgradeReceiver", document.getElementById("emuUpgradeDialog"));
                webSocketSendMsg(JSON.stringify({

                    deviceNum: this.upgradeInfo.deviceNum,
                    deviceType: this.upgradeInfo.deviceType,
                    deviceWholeId : this.upgradeInfo.deviceWholeId + '',
                    fileName: this.upgradeInfo.fileName,
                    filePath: this.upgradeInfo.filePath,
                    crc32: this.upgradeInfo.crc32
                }));
            }
        },
        mounted() {

            this.getOrgList();
            this.getStationOption();
            this.getTableData();
        }
    }
</script>

<style scoped>

</style>
<style>

</style>
