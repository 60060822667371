import axios from 'axios';
import global from '../../components/Global';

let base = global.serverBase;
export const queryDeviceInfo = params => { return axios.post(base + '/upgrade/getDeviceInfoList', params)};
export const queryOrgInfo = params => { return axios.post(base + '/upgrade/queryOrgInfo', params)};
export const queryCascaderAreaInfoByOrgId = params => { return axios.post(base + '/upgrade/queryCascaderAreaInfoByOrgId', params)};
export const queryStationInfoByParam = params => { return axios.post(base + '/upgrade/queryStationInfoByParam', params)};
export const queryUpgradeProgressList = params => { return axios.post(base + '/upgrade/queryUpgradeProgressList', params)};



