import global from "../components/Global";
import {Loading} from 'element-ui';
import { Message } from 'element-ui';
let websocket = null;
let heartCheckTimer = null;
let closeLoadingTimer = null;
let loading = null;
let targetObj = null;
/**
 * 初始化websocket.
 * @param url 请求路径(只写请求部分即可 如:/device)
 * @param successCallBack 成功的回调函数
 * @param errorCallBack 失败的回调函数
 * @param finalCallBack 不管怎么样一定会执行的函数
 * @returns {boolean}
 */
export function initWebSocket (url, target, successCallBack, errorCallBack, finalCallBack) {

    if (typeof (WebSocket) === 'undefined') {

        console.log('您的浏览器不支持WebSocket，无法获取数据');
        return false;
    }
    if (websocket == null) {

        targetObj = target;
        // 请求路径
        let wsUrl = (global.serverBase).replace('http' , 'ws') + url;
        // 创建websocket
        websocket = new WebSocket(wsUrl);
        // 注册对应方法
        websocket.onopen = function (e) {

            console.log('WebSocket连接成功');
        };
        websocket.onmessage = function (e) {

            // 如果为ping则是心跳响应
            if (e.data == "ping") {

                console.log("接收到服务器的心跳响应:" + e.data);
                return;
            }
            console.log(e.data);
            let data = JSON.parse(e.data);
            // 关闭加载圈
            closeLoading();
            if (data =="400" || data == "500") {

                // 提示用户指令参数错误
                Message({

                    message: '指令参数错误',
                    center: true,
                    type: 'error'
                });
                if (errorCallBack) {

                    errorCallBack(data);
                }
            } else if (data.ackCode == 1){

                // 提醒执行成功
                Message({

                    message: '指令执行成功',
                    center: true,
                    type: 'success'
                });
                if (successCallBack) {

                    successCallBack(data);
                }
            } else if (data.ackCode == 2) {

                // 提醒执行失败
                Message({

                    message: '指令执行失败',
                    center: true,
                    type: 'warning'
                });
                if (errorCallBack) {

                    errorCallBack(data);
                }
            } else if (data.ackCode == -4) {

                // 提醒设备不在线
                Message({

                    message: '设备不在线',
                    center: true,
                    type: 'warning'
                });
                if (errorCallBack) {

                    errorCallBack(data);
                }
            }

            // 不管怎么样，只要传进来了，就会执行
            if (finalCallBack) {

                finalCallBack(data);
            }
        };
        websocket.onerror = function(e) {

            // 清除发送心跳定时器
            clearInterval(heartCheckTimer);
            // 关闭加载圈
            closeLoading();
            console.log('WebSocket连接发生错误:' + JSON.stringify(e));
        };
        websocket.onclose = function(e) {

            // 清除发送心跳定时器
            clearInterval(heartCheckTimer);
            // 关闭加载圈
            closeLoading();
            // 断开连接后将遮罩层对象置空
            targetObj = null;
            websocket = null;
            console.log('WebSocket连接关闭');
        };
        // 开始发送心跳
        heartCheck();
    }
}

/**
 * webSocket向服务器发送消息.
 * @param msg 消息
 */
export function webSocketSendMsg(msg) {

    // 防止还未建立连接就发送信息
    let timer = setInterval(()=>{

        if (websocket.readyState == WebSocket.OPEN) {

            // 连接成功就清除timer
            clearInterval(timer);
            // 开启加载圈
            loading = Loading.service({

                fullscreen: true,
                text: "指令执行中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                target: targetObj
            });
            // 超时60s就关闭加载圈
            closeLoadingTimer = setTimeout(() => {

                // 关闭加载圈
                closeLoading();
            }, 60000);
            // 发送
            websocket.send(msg);
        }
    }, 500);
}

/**
 * 发送心跳(60s).
 */
function heartCheck() {

    heartCheckTimer = setInterval(() =>{

        // 已经open 发送心跳消息
        if (websocket.readyState == WebSocket.OPEN) {

            websocket.send("ping");
            console.log("浏览器发送心跳请求:ping" )
        }
    }, 60000);
}

/**
 * 关闭加载圈.
 */
function closeLoading() {

    if (loading) {

        loading.close();
        loading = null;
        // 防止下发多次指令时,第一次的60s到时清除当前的加载圈
        clearTimeout(closeLoadingTimer);
    }
}
/**
 * 关闭websocket.
 */
export function closeWebSocket () {

    if (websocket) {

        // 关闭websocket
        websocket.close();
    }
}

export function resetWebSocket() {

    closeWebSocket();
    websocket = null;
    heartCheckTimer = null;
    closeLoadingTimer = null;
    loading = null;
    targetObj = null;
}